import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import PeopleGrid from '../components/people-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

import MissionImg from '../assets/img/mission.png'
import HeroImg from '../assets/img/story.png'


import '../styles/about.scss'

import { responsiveTitle1 } from '../components/typography.module.scss'
import SessionBanner from '../components/session-banner'

export const query = graphql`
  query AboutPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawBody
      _rawExtraDescription1
      _rawExtraDescription2
      _rawExtraDescription3
    }
    people: allSanityPerson {
      edges {
        node {
          id
          image {
            asset {
              _id
              url
            }
          }
          name
          title
          slug{
            current
          }
          _rawBio
          _rawExcerpt
          linkedIn
          resume{
            asset{
              url
            }
          }
        }
      }
    }
  }
`

const AboutPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page
  const peopleNodes = (data || {}).people ? mapEdgesToNodes(data.people) : []

  if (!page) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={page.title} />
      <section id="story">
        <Container>
          <div className='row'>
            <div className='col-lg align-self-center title'>
              <img style={{ marginBottom: '24px' }} src={HeroImg} />
              <h1 style={{ marginBottom: '24px' }}>{page.title}</h1>
            </div>
            <div className='col-lg align-self-center'>
              <BlockContent blocks={page._rawBody || []} />
            </div>
          </div>
        </Container>
      </section>
      <section id="mission">
        <Container>
          <div>
            <img src={MissionImg} />
            <h1>Our Mission</h1>
            <div className='row'>
              <div className='col-md-4'>
                <BlockContent blocks={page._rawExtraDescription1 || []} />
              </div>
              <div className='col-md-4'>
                <BlockContent blocks={page._rawExtraDescription2 || []} />
              </div>
              <div className='col-md-4'>
                <BlockContent blocks={page._rawExtraDescription3 || []} />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section id='team'>
        <div className='noise'></div>
        <Container>
          <div className='bios'>
          <h2>Meet Our Team</h2>
          {peopleNodes && (
            peopleNodes.map(node => (
              <div className='bio row' key={node.id} id={node.slug.current}>
                <div className='imgBlock col-md align-self-center'>
                  <img src={node.image.asset.url} />
                  <div className='name'>
                    {node.name}
                  </div>
                  <div className='title'>
                    {node.title}
                  </div>
                  <div className='linkGroup row'>
                  <a className='linkedIn col' href={node.linkedIn} target="_blank">
                    LinkedIn
                  </a>
                  <a className='cv col' href={node.resume.asset.url} target="_blank">
                    CV
                  </a>
                  </div>
                </div>
                <div className='col-md' style={{ textAlign: 'left' }}>
                  <BlockContent blocks={node._rawBio} />
                </div>
              </div>
            ))
          )}
          </div>
        </Container>
      </section>
      <SessionBanner />
    </Layout>
  )
}

export default AboutPage
