import React from 'react'

import Container from './container'

import styles from './session-banner.module.css'


function SessionBanner() {
    return (
        <section className={styles.section}>
            <Container>
                <div className='row'>
                    <div className='col-md'>
                        <h2 style={{textAlign: 'left'}}>Training Sessions, Workshops, Lunch & Learn, Conference Lectures</h2>
                        <h3 style={{color: '#8E93FF', marginBottom: '24px'}}>Includes Pre-Meeting & Post Report</h3>
                        <p>We offer expert recommendations to improve your ophthalmic business. We work directly with you to create educational and training plans that fit you and your business.</p>
                    </div>
                    <div className={styles.right + ' col-md'}>
                        <a className={styles.linkButton} href='https://forms.gle/pH3Vr64uW53FQM4b7' target='_blank'>Plan your session</a>
                        <div className={styles.price}>$250 Consultation Fee</div>
                        <div>Applied toward services, if retained</div>
                        <div className={styles.sessionTypes}>
                            <div>One-on-One</div>
                            <div>Small Groups</div>
                            <div>Doctor & Staff</div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default SessionBanner